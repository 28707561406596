import React, { useReducer, createContext, useContext } from "react";

const ACTIONS = {
  ON_SELECT_EMPLOYEE: "ON_SELECT_EMPLOYEE",
  INIT_EMPLOYEES: "INIT_EMPLOYEES",
  SET_TECH_SHARE: "SET_TECH_SHARE",
};

const initialState = {
  employees: [],
  techShare: false,
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.ON_SELECT_EMPLOYEE: {
      const { payload } = data;
      const { employee, previousEmployee } = payload;
      const employees = [...state.employees];

      if (previousEmployee) {
        const index = employees.findIndex((e) => e.id === previousEmployee?.id);

        if (index >= 0) {
          employees.splice(index, 1, employee);
        }
      }

      if (employee) {
        const exists = employees.find((e) => e.id === employee.id);
        if (!exists) {
          employees.push(employee);
        }
      }

      return {
        ...state,
        employees: employees.filter(Boolean),
      };
    }

    case ACTIONS.INIT_EMPLOYEES: {
      const { payload } = data;
      const { employees } = payload;
      return {
        ...state,
        employees: employees.filter(Boolean),
      };
    }

    case ACTIONS.SET_TECH_SHARE: {
      const { payload } = data;
      const { techShare } = payload;
      return {
        ...state,
        techShare,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const ScheduleModalTechniciansContext = createContext(initialState);

const ScheduleModalTechniciansProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ScheduleModalTechniciansContext.Provider value={stateAndDispatch}>
      {children}
    </ScheduleModalTechniciansContext.Provider>
  );
};

export const useScheduleModalTechnicians = () =>
  useContext(ScheduleModalTechniciansContext);

export {
  ScheduleModalTechniciansContext,
  ScheduleModalTechniciansProvider,
  ACTIONS,
};
