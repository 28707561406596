import {
  faChevronLeft,
  faChevronRight,
  faFileCsv,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Button from "reactstrap/lib/Button";
import { reportsApi } from "../../../services/reportsServices";
import moment from "moment";
import { useAuth } from "../../../providers/authProvider";
import InformationModal from "../../../components/InformationModal";
import Loader from "../../../components/Loader";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const JobCostReport = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const previousMonday = () => setMonday(moment(monday).add(-1, "week"));

  const toggleDownloadOptions = () => setDropdownOpen(!dropdownOpen);

  const nextMonday = () => setMonday(moment(monday).add(1, "week"));

  const getSunday = () => moment(monday).endOf("isoWeek");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    reportsApi
      .jobCostReport({
        weekStart: monday.format("YYYY-MM-DD"),
        serviceLocationId: authContext.serviceLocationId,
      })
      .then((result) => {
        setLoading(false);
        setData(result);
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      });
  }, [monday, authContext.serviceLocationId]);

  const shouldShowDoubleTime =
    data.length && data.some((entry) => entry.isCaliforniaPayroll);

  const onExport = (format) => {
    const exportData = data.reduce((p, c) => {
      c.jobNumber.forEach((jobNumber) => {
        const item = {
          "Employee Id": c.employeeId,
          "Employee Name": c.employeeName,
          Date: moment(c.date).format("MM/DD/YYYY"),
          Department: c.serviceLocationEmployee[0]?.serviceLocation?.name,
          "Job Number": jobNumber,
          "Regular Hours": c.regHours[jobNumber],
          "Overtime Hours":
            !c.isCaliforniaPayroll && c.dtHours[jobNumber] > 0
              ? c.otHours[jobNumber] + c.dtHours[jobNumber]
              : c.otHours[jobNumber],
          ...(shouldShowDoubleTime && {
            "Double Hours": c.isCaliforniaPayroll ? c.dtHours[jobNumber] : 0,
          }),
        };
        p.push(item);
      });
      return p;
    }, []);

    const filename = `JobCostReport_${monday.format("MM/DD/YYYY")}`;

    if (format === "csv") {
      const CSVDownloadData = Papa.unparse(exportData, { delimiter: ";" });
      const hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv; charset=utf-8,\ufeff" +
        encodeURIComponent(CSVDownloadData);
      hiddenElement.target = "_self";
      hiddenElement.download = `${filename}.csv`;
      hiddenElement.click();
    } else if (format === "xlsx") {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "JobCostReport");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
  };

  const onSeeWODetails = (workOrderId) =>
    navigate(`/work-orders/${workOrderId}/details`);

  return (
    <Container fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Job Cost Report</h3>
            <small className="text-muted ml-2 pt-1">({data.length})</small>
          </div>
          <div className="d-flex justify-content-between">
            <Button className="my-1" onClick={previousMonday}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <strong className="my-1 mx-1 p-2 border rounded">
              {monday.format("MM/DD/YYYY")}
              {" - "}
              {getSunday().format("MM/DD/YYYY")}
            </strong>
            <Button
              className="my-1"
              onClick={nextMonday}
              disabled={getSunday().isAfter(moment())}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDownloadOptions}
            direction="end"
            size="sm"
            className="ml-2 rounded"
            disabled={!data?.length}
          >
            <DropdownToggle caret>Download Options</DropdownToggle>
            <DropdownMenu style={{ marginTop: 30 }}>
              <DropdownItem onClick={() => onExport("csv")}>
                <FontAwesomeIcon icon={faFileCsv} className="mr-2" />
                CSV File
              </DropdownItem>
              <DropdownItem onClick={() => onExport("xlsx")}>
                <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                XLS File
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Table className="mb-0">
              <thead className="small text-muted">
                <tr>
                  <th className="border-top-0">Employee Id</th>
                  <th className="border-top-0">Employee Name</th>
                  <th className="border-top-0">Date</th>
                  <th className="border-top-0">Department</th>
                  <th className="border-top-0">Job Number</th>
                  <th className="border-top-0">Regular Hours</th>
                  <th className="border-top-0">Overtime Hours</th>
                  {shouldShowDoubleTime ? (
                    <th className="border-top-0">Double Time</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((entry) =>
                    entry.jobNumber.map((jobNumber, index) => (
                      <tr key={index}>
                        <td className="text-truncate">
                          {entry.employeeId || "-"}
                        </td>
                        <td className="text-truncate">{entry.employeeName}</td>
                        <td className="text-truncate">
                          {moment(entry.date).format("MM/DD/YYYY")}
                        </td>
                        <td className="text-truncate">
                          {entry.serviceLocationEmployee[0]?.serviceLocation
                            ?.name || "-"}
                        </td>
                        <td className="text-truncate">
                          <span
                            onClick={() =>
                              onSeeWODetails(entry.jobIds[jobNumber])
                            }
                            className="cursor-pointer text-link"
                          >
                            {jobNumber}
                          </span>
                        </td>
                        <td className="text-truncate">
                          {entry.regHours[jobNumber]}
                        </td>
                        <td className="text-truncate">
                          {!entry.isCaliforniaPayroll &&
                          entry.dtHours[jobNumber] > 0
                            ? entry.otHours[jobNumber] +
                              entry.dtHours[jobNumber]
                            : entry.otHours[jobNumber]}
                        </td>
                        {shouldShowDoubleTime ? (
                          <td className="text-truncate">
                            {entry.isCaliforniaPayroll
                              ? entry.dtHours[jobNumber]
                              : "-"}
                          </td>
                        ) : null}
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center text-muted small">
                      No data has been recorded
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default JobCostReport;
